import _ from "lodash";

// initial state

const state = () => ({
  openTabs: [[], []],
  openTabNames: [[], []],
  selTab: [-1, -1],
  selTabChanged: 0,
});

const addMultiGraphTab = function(state, tabObj) {
  const tabName = tabObj.value;
  const pipelineId = tabObj.pipelineId;
  const tabId = tabObj.id;
  const openTabIndex = _.findIndex(state.openTabs[pipelineId], (t) => {
    return t.id == tabId && !t.isZoom;
  });

  // just change the selTab if it is already open
  if (openTabIndex !== -1) {
    state.selTab[pipelineId] = openTabIndex;
    // add a new entry if the name alreay exists
  } else if (state.openTabNames[pipelineId].includes(tabName)) {
    const sameName = _.filter(state.openTabNames[pipelineId], (n) => {
      return n == tabName;
    });
    state.openTabs[pipelineId].push(tabObj);
    state.openTabNames[pipelineId].push(
      tabName + " (" + (sameName.length + 1) + ")"
    );
    state.selTab[pipelineId] = state.openTabs[pipelineId].length - 1;
  } else {
    state.openTabs[pipelineId].push(tabObj);
    state.openTabNames[pipelineId].push(tabName);
    state.selTab[pipelineId] = state.openTabs[pipelineId].length - 1;
  }
};

const addZoomTab = function(state, tabObj) {
  const pipelineId = tabObj.pipelineId;

  const openTabIndex = _.findIndex(state.openTabs[pipelineId], (t) => {
    return t.rowId == tabObj.rowId && t.isZoom && t.type == tabObj.type;
  });

  // if plot is already open we just switch to it
  if (openTabIndex !== -1) {
    state.selTab[pipelineId] = openTabIndex;
  } else {
    state.openTabs[pipelineId].push(tabObj);
    state.openTabNames[pipelineId].push(tabObj.name);
    state.selTab[pipelineId] = state.openTabs[pipelineId].length - 1;
  }
};

// mutations
const mutations = {
  addTab(state, tabObj) {
    if (tabObj.isZoom) {
      addZoomTab(state, tabObj);
    } else {
      addMultiGraphTab(state, tabObj);
    }
    state.selTabChanged = state.selTabChanged + 1;
  },

  selectTab(state, payload) {
    state.selTab[payload.pipelineId] = payload.idx;
    state.selTabChanged = state.selTabChanged + 1;
  },

  closeTab(state, payload) {
    const indexToClose = state.openTabs[payload.pipelineId].findIndex(
      (item) => {
        if (payload.isZoom) {
          return item.type === payload.id && item.name === payload.tabName;
        } else {
          return item.id === payload.id;
        }
      }
    );

    state.openTabs[payload.pipelineId] = state.openTabs[
      payload.pipelineId
    ].filter((item, idx) => {
      return idx !== indexToClose;
    });

    state.openTabNames[payload.pipelineId] = state.openTabNames[
      payload.pipelineId
    ].filter((item, idx) => {
      return idx !== indexToClose;
    });

    state.selTab[payload.pipelineId] =
      state.openTabNames[payload.pipelineId].length - 1;
    state.selTabChanged = state.selTabChanged + 1;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};

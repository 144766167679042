<template>
  <v-container fluid>
    <v-row>
      <!-- logo -->
      <v-col class="col-5">
        <div class="d-flex">
          <v-btn text to="/">
            eQTLs Interface for <em>Candida alibcans</em>
          </v-btn>
        </div>
      </v-col>

      <v-col class="col-5">
        <div class="d-flex">
          <v-btn text to="/pipeline1">
            Pipeline 1
          </v-btn>
          <v-btn text to="/pipeline2">
            Pipeline 2
          </v-btn>
        </div>
      </v-col>
      <v-col class="col-2 text-right">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on">
              Contact
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <a href="mailto:abhilifesicizurich@gmail.com">Abhilash&nbsp;Kannan</a>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "NavBar",
};
</script>

<style scoped></style>
